<template>
  <div class="advocacy">
    <div class="advocacy__header">
      <h3 class="advocacy__title">
        Détails du plaidoyer par {{advocacy.user.firstname + ' ' + advocacy.user.lastname}}
      </h3>
    </div>
    <div class="advocacy__body">
      <dl class="advocacy__divider">
        <div class="advocacy__item">
          <dt class="advocacy__question">
            Sujet
          </dt>
          <dd class="advocacy__answer">
            {{advocacy.subject}}
          </dd>
        </div>
        <div class="advocacy__item">
          <dt class="advocacy__question">
            Contenu
          </dt>
          <dd class="advocacy__answer">
            {{advocacy.body}}
          </dd>
        </div>
        <div v-if="advocacy.file" class="advocacy__item">
          <dt class="advocacy__question">
            Attachement
          </dt>
          <dd class="advocacy__answer">
            <a target="_blank" :href="imageUrl(advocacy.file.path)" class="text-primary underline">
              Voir l'attachement
            </a>
          </dd>
        </div>
      </dl>
    </div>
    <div class="advocacy__cta">
      <atom-button variant="success md" @click.prevent="emit('modalClose');">Fermer</atom-button>
    </div>
  </div>
</template>

<script setup>
import {toRefs, computed, inject } from "vue";
import {useStore} from "vuex";
const emit = defineEmits(['modalClose'])
const {dispatch} = useStore();
const props = defineProps({
  advocacy:{
    type:Object,
    required:true
  }
});
const {advocacy} = toRefs(props);
const imageUrl = inject('imageUrl');
</script>

<style lang="scss" scoped>
.advocacy{
  &__header{
    @apply px-4 py-5 sm:px-6;
  }
  &__title{
    @apply text-lg leading-6 font-medium text-gray-900;
  }
  &__description{
    @apply mt-1 max-w-2xl text-sm text-gray-500 capitalize;
  }
  &__body{
    @apply border-t border-gray-200 px-4 py-5 sm:p-0;
  }
  &__divider{
    @apply sm:divide-y sm:divide-gray-200;
  } 
  &__item{
    @apply py-4 sm:py-5
    flex flex-col gap-4;
  }
  &__question{
    @apply text-sm font-medium text-gray-500;
  }
  &__answer{
    @apply mt-1 text-sm text-gray-900 sm:mt-0;
  }
  &__cta{
    @apply flex justify-end gap-3 mt-2;
  }
}
</style>