<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div
          class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
        >
          <table class="advocacies-table">
            <thead class="advocacies-table__header">
              <tr>
                <th scope="col" class="advocacies-table__header-col">Plaidoyeur</th>
                <th scope="col" class="advocacies-table__header-col">Sujet</th>
                <th scope="col" class="advocacies-table__header-col">Corps</th>
                <th scope="col" class="relative px-6 py-3">
                  <span class="sr-only">Actions</span>
                </th>
              </tr>
            </thead>
            <tbody class="advocacies-table__body">
              <tr
                class="advocacies-table__row"
                v-for="advocacy in advocacies"
                :key="advocacy.id"
              >
                <td class="advocacies-table__row-cel">
                  <div class="flex items-center">
                    <div class="flex-shrink-0 h-10 w-10">
                      <img
                        v-if="advocacy.user?.avatar"
                        class="h-10 w-10 rounded-full"
                        :src="imageUrl(advocacy.user?.avatar)"
                        :alt="`${person.firstname + ' ' + person.lastname}`"
                      />
                      <div v-else
                        class="h-10 w-10 rounded-full bg-primary flex justify-center items-center"
                      >
                        <span class="uppercase text-white text-base font-semibold tracking-widest">{{advocacy.user.firstname[0] + advocacy.user.lastname[0]}}</span>
                      </div>
                    </div>
                    <div class="ml-4">
                      <div class="text-sm font-medium text-gray-900">
                        {{ advocacy.user.firstname }} {{advocacy.user.lastname}}
                      </div>
                      <div class="text-sm text-gray-500">
                        {{ advocacy.user.email }}
                      </div>
                    </div>
                  </div>
                </td>
                <td class="advocacies-table__row-cel">
                  <div class="text-sm font-medium text-gray-900">
                    {{ advocacy.subject }}
                  </div>
                </td>
                <td class="advocacies-table__row-cel advocacies-table__row-cel--fixed">
                  <div class="text-sm text-gray-900">
                    <span>{{advocacy.body.slice(0,100)}}...</span>
                  </div>
                </td>
                <td class="advocacies-table__row-cel text-right text-sm font-medium">
                  <a href="#" @click.prevent="emit('showAdvocacy', advocacy)" class="text-primary hover:text-primary/90 mr-2" 
                    >
                    Voir
                  </a>
                  <button @click.prevent="deleteAdvocacy(advocacy.id)" class="text-red-500 hover:text-red-400 font-semibold"
                    >
                    Supprimer
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <molecule-pagination 
      v-if="pageInfos" 
      @pageChange="getPageData($event)" 
      :pageInfos="pageInfos"
      :currentPage="currentPage"
    ></molecule-pagination>
  </div>
</template>

<script setup>
import { computed, toRefs, onBeforeMount, ref, inject } from "vue";
import MoleculePagination from "@/components/molecules/MoleculePagination.vue";
import {useStore} from "vuex";
const {state, dispatch} = useStore();
const advocacies = computed( () => state.advocacies.advocacies?.data);
const pageInfos = computed( () => state.advocacies.advocacies?.meta);
const currentPage = ref(1);
const emit = defineEmits('showAdvocacy');
const fetchAdvocacies = async () => {
  await dispatch('advocacies/getAll', currentPage.value);
}
const getPageData = async (page) => {
  currentPage.value = page;
  await fetchAdvocacies();
}
const deleteAdvocacy = async (id) => {
  try {
    const deletedAdvocacy = await dispatch('advocacies/delete', id);
  } catch (err) {
    console.log(err);
  }
}

onBeforeMount( async () => {
  await fetchAdvocacies();
});

const imageUrl = inject('imageUrl');
</script>

<style lang="scss" scoped>
.advocacies-table {
  @apply min-w-full divide-y divide-gray-200 table-fixed;
  &__header {
    @apply bg-gray-50;
    &-col {
      @apply px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider;
    }
  }
  &__body {
    @apply bg-white divide-y divide-gray-200;
  }
  &__row {
    &-cel {
      @apply px-6 py-4 whitespace-nowrap;
      &--fixed{
        @apply min-w-[250px] 
        whitespace-normal;
      }
      &-status {
        @apply px-2 inline-flex text-xs leading-5 font-semibold rounded-full;
        &--active {
          @apply bg-green-100 text-green-600;
        }
        &--blocked {
          @apply bg-red-100 text-red-600;
        }
        &--pending {
          @apply bg-gray-100 text-gray-600;
        }
      }
    }
  }
}
</style>
