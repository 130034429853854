<template>
  <div class="mt-5">
    <block-dashboard-wrapper title="Liste des plaidoyers">
      <block-advocacies-table 
        @showAdvocacy="showAdvocacy($event)"
      />
    </block-dashboard-wrapper>
    <molecule-modal-wrapper :open="isModalOpen" @modalClose="closeModal()">
      <block-advocacy-details 
        :advocacy="activeAdvocacy"
        @modalClose="closeModal()"
      />
    </molecule-modal-wrapper>
  </div>
</template>

<script setup>
import BlockDashboardWrapper from "@/components/blocks/BlockDashboardWrapper.vue";
import BlockAdvocaciesTable from "@/components/blocks/tables/BlockAdvocaciesTable.vue";
import MoleculeModalWrapper from "@/components/molecules/MoleculeModalWrapper.vue";
import BlockNewsForm from "@/components/blocks/forms/BlockNewsForm.vue";
import BlockAdvocacyDetails from "@/components/blocks/BlockAdvocacyDetails.vue";
import { ref } from "vue";

  // Modal code
  const isModalOpen = ref(false);
  const isEditMode = ref(false);
  const activeAdvocacy =ref(null);
  const showAdvocacy = (payload) => {
    isModalOpen.value = true;
    activeAdvocacy.value = payload;
  }
  const closeModal = () => {
    isModalOpen.value = false;
  };
</script>

<style lang="scss" scoped></style>
