<template>
  <div class="pagination">
    <div class="pagination__arrows">
      <a href="#" class="pagination__arrow"> Précedant </a>
      <a href="#" class="pagination__arrow"> Suivant </a>
    </div>
    <div class="pagination__infos">
      <div>
        <p class="pagination__info">
          Montrant
          {{ ' ' }}
          <span class="font-medium">{{ ((currentPage - 1) * itemsPerPage) + 1 }}</span>
          {{ ' ' }}
          à
          {{ ' ' }}
          <span class="font-medium">{{ currentPage == totalPages ? totalItems : currentPage * itemsPerPage }}</span>
          {{ ' ' }}
          de
          {{ ' ' }}
          <span class="font-medium">{{ totalItems }}</span>
          {{ ' ' }}
          résultats
        </p>
      </div>
      <div>
        <nav class="pagination__navigation" aria-label="Pagination">
          <a @click.prevent="emit('pageChange', currentPage - 1)" :class="`pagination__navigation-arrow ${currentPage == 1 ? 'pagination__navigation-arrow--disabled':''}`">
            <span class="sr-only">Précedant</span>
            <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
          </a>
          <!-- Current: "z-10 bg-indigo-50 border-primary text-primary", Default: "bg-white border-gray-200 text-gray-500 hover:bg-gray-50" -->
          <template v-if="totalPages > 1">
            <a v-for="index in totalPages" :key="index" @click.prevent="emit('pageChange', index)" aria-current="page" :class="`pagination__navigation-item ${index == currentPage ? 'pagination__navigation-item--active':''}`">{{index}}</a>
          </template>
          <a @click.prevent="emit('pageChange', currentPage + 1)" :class="`pagination__navigation-arrow pagination__navigation-arrow--right ${currentPage == totalPages ? 'pagination__navigation-arrow--disabled':''}`">
            <span class="sr-only">Suivant</span>
            <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
          </a>
        </nav>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/solid';
import {toRefs} from "vue"
const props = defineProps({
  pageInfos:{
    type:Object,
    required:true
  },
  currentPage:{
    type:Number,
    required:true
  }
});
const {pageInfos:{value:{totalPages, totalItems, itemsPerPage}}, currentPage} = toRefs(props);
const emit = defineEmits(['pageChange']);
</script>

<style lang="scss" scoped>
  .pagination{
    @apply bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6;
    &__arrows{
      @apply flex-1 flex justify-between sm:hidden;
    }
    &__arrow{
      @apply relative inline-flex items-center px-4 py-2 border border-gray-200 text-sm font-medium rounded-md text-gray-500 bg-white hover:bg-gray-50;
    }
    &__infos{
      @apply hidden sm:flex-1 sm:flex sm:items-center sm:justify-between;
    }
    &__info{
      @apply text-sm text-gray-500;
    }
    &__navigation{
      @apply relative z-0 inline-flex rounded-md shadow-sm -space-x-px;
      &-item{
        @apply cursor-pointer bg-white border-gray-200 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium;
        &--active{
          @apply relative z-10 bg-primary/10 border-primary/90 text-primary;
        }
      }
      &-arrow{
        @apply relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-200 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50;
        &--right{
          @apply rounded-none rounded-r-md;
        }
        &--disabled{
          @apply pointer-events-none cursor-pointer text-gray-200;
        }
      }
    }
  }
</style>